
import MainButton from "@/components/utils/buttons/MainButton"
import ChangeLanguage from "@/components/v2/forms/ChangeLanguage.vue"
export default {
  components: {ChangeLanguage, MainButton },
  layout: "auth",
  head() {
    return {
      title: this.$t('startSession')
    }
  },
  data(){
    return{
      error: false,
      auth: {
        email: null,
        password: null,
        phone_number: "",
        phone_indicative: "+351",
        code: null
      },
      passwordRecovery: false,
      recoverySucessfull: false,
      recoveryEmail: null,
      insert_phone_code: false,
      loading: false,
      showPassword: false,
      activeTab: 0,
      tabs: [
        { name: "E-mail" },
        { name: this.$t('phoneNumber') },
      ],
      has_guest_invite: null,
    }
  },
  mounted(){
    const guestInviteID = this.$route.params.guest_invite
    if(guestInviteID){
      this.has_guest_invite = guestInviteID
    }
    window.recaptchaVerifier = new this.$fireModule.auth.RecaptchaVerifier('sign-in-button', {
      'size': 'invisible'
    })
  },
  methods: {
    setActiveTab(tab){
      this.loading = false
      this.error = false
      this.activeTab = tab
    },
    async signInWithEmailAndPassword(email, password){
      this.loading = true
      this.error = false
      try {
        await this.$fire.auth.signInWithEmailAndPassword(email, password)
        this.setFirebaseEvent('login', { provider: 'email_and_password' })
      } catch(error) {
        this.loading = false
        this.checkErrorMessage(error)
      }
    },
    async signInWithPhoneNumber(indicative, phone_number){
      let vm = this
      this.loading = true
      this.error = false
      let phoneNumber = indicative + phone_number
      var appVerifier = window.recaptchaVerifier
      await this.$fire.auth.signInWithPhoneNumber(phoneNumber, appVerifier).then(function(confirmationResult){
        window.confirmationResult = confirmationResult
        vm.loading = false
        vm.insert_phone_code = true
      })
      .catch(function(error){
        vm.loading = false
        vm.error = error.message
      })
    },
    async setUserCode(code){
      let vm = this
      this.loading = true
      window.confirmationResult.confirm(code).then((result) => {
        const user = result.user
      }).catch((error) => {
        this.loading = false
        vm.error = error.message
      })
      this.setFirebaseEvent('login', { provider: 'phone_number' })
    },
    async logInWithFacebook(){
      let vm = this
      let provider = new this.$fireModule.auth.FacebookAuthProvider()
      await this.$fire.auth.signInWithPopup(provider).then(result => {
        vm.setFirebaseEvent('login', { provider: 'facebook' })
      }).catch((error) => {
        vm.error = error.message
      })
    },
    async logInWithGoogle(){
      let vm = this
      let provider = new this.$fireModule.auth.GoogleAuthProvider()
      await this.$fire.auth.signInWithPopup(provider).then(result => {
        vm.setFirebaseEvent('login', { provider: 'google' })
      }).catch((error) => {
        vm.error = error.message
      })
    },
    showPasswordText(){
      this.showPassword = !this.showPassword
    },
    checkErrorMessage(error){
      if(error.message === "The email address is badly formatted."){
        this.error = this.$t('fillAllFields')
      } else if(error.message === "The password is invalid or the user does not have a password."){
        this.error = this.$t('wrongEmailPassword')
      } else if(error.message === "There is no user record corresponding to this identifier. The user may have been deleted."){
        this.error = this.$t('noUserFound')
      } else {
        this.error = error.message
      }
    },
    forgotPassword(){
      this.error = null
      this.passwordRecovery = true
    },
    doPasswordRecovery(){
      try{
        this.$fire.auth.sendPasswordResetEmail(this.recoveryEmail)
        this.error = null
        this.recoveryEmail = null
        this.passwordRecovery = false
        this.recoverySucessfull = true
      } catch(e){
        this.checkErrorMessage(e)
      }
    },
    resetLogin(){
      this.error = null
      this.recoverySucessfull = false
    }
  }
}
